import React, { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Tabs, Select, Divider, Space, message, Skeleton } from 'antd';
import FieldsDropdown from './FieldsDropdown';
import Config from '../../Config';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';

const AddSupplierModal = (props) => {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");
    const [loading, setLoading] = useState(false);
    const [SupplierData, setSupplierData] = useState('');
    const [OpenDate, setOpenDate] = useState(null);
    const [SupplierLoading, setSupplierLoading] = useState(false);

    const [formMain] = Form.useForm();


    const [Supplier, setSupplier] = useState(false);
    const [Filer, setFiler] = useState(false);

    useEffect(() => {
        if (props && props.SupplierID) {
            setSupplierLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: props.SupplierID,
            };

            var api_config = {
                method: "post",
                url: Config.base_url + "Supplier/GetSupplierByID",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        setSupplierData(response.data.Supplier)
                        setSupplierLoading(false);

                        formMain.setFieldsValue(response.data.Supplier);

                        let date = response.data.Supplier?.OpeningDate;



                        setOpenDate(dayjs(date).format("YYYY-MM-DD"));
                    }
                    else {
                        // message.error(response.data.status_message);
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    }, [props && props.SupplierID])

    const handleDateChange = (e, value) => {
        setOpenDate(value);
    }
    const Address = () => {
        return <>

            <Row gutter={[24, 0]}>
                <Col xs={24} md={24}>
                    <Form.Item name='ShippingAddress' label='Billing Address'>
                        <Input placeholder="Billing Address" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='ShippingCity'>
                        <Input placeholder="City" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='ShippingProvince'>
                        <Input placeholder="Province" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='ShippingPostalCode'>
                        <Input placeholder="Postal Code" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='ShippingCountry'>
                        <Input placeholder="Country" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    }
    const TaxInfo = () => {
        return <>

            <Row gutter={[24, 0]} align='bottom'>
                <Col xs={24} md={8}>
                    <Form.Item name='NTNNumber' label='Tax Info'>
                        <Input placeholder="NTN" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item name='SalesTaxNumber'>
                        <Input placeholder="STN" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item name='CNIC'>
                        <Input placeholder="CNIC" min={13} max={13} />
                    </Form.Item>
                </Col>

            </Row>
        </>
    }
    // console.log(OpenDate === null ? (dayjs().format('YYYY-MM-DD')) : dayjs(OpenDate, "YYYY-MM-DD"))
    const Terms = () => {
        return <>
            <Row gutter={[24, 0]} align='bottom'>
                <Col xs={24} md={6}>
                    <Form.Item name='PaymentTermDays' label='Terms'>
                        <Input placeholder="Payments Term days" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='CreditLimit' >
                        <Input placeholder="Credit Limit" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item>
                        <DatePicker defaultValue={OpenDate === null ? (dayjs()) : dayjs(OpenDate, "YYYY-MM-DD")} style={{ width: "100%" }} onChange={(value, mode) => setOpenDate(value)} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='OpeningBalance'>
                        <Input placeholder='Opening Balance' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item>
                        <Checkbox checked={Supplier} onChange={(e) => setSupplier(!Supplier)}>Customer</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </>
    }
    const Bank = () => {
        return <>
            <Row gutter={[24, 0]}>
                <Col xs={24} md={6}>
                    <Form.Item name='BankName'>
                        <Input placeholder='Bank Name' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='AccountName'>
                        <Input placeholder='Account Name' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='AccountNumber'>
                        <Input placeholder='Account Number' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='IBANNumber'>
                        <Input placeholder='IBAN' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='SwiftCode'>
                        <Input placeholder='Swift Code' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item name='Address'>
                        <Input placeholder='Address' />
                    </Form.Item>
                </Col>
            </Row>
        </>
    }
    const Notes = () => {
        return <>
            <Row gutter={[24, 0]}>
                <Col xs={24} md={24}>
                    <Form.Item name='Notes' label="Notes">
                        <Input.TextArea placeholder='Notes' rows={6} />
                    </Form.Item>
                </Col>

            </Row>
        </>
    }

    const Fields = () => {
        return (
            <>
                <Row gutter={[24, 0]} align='bottom'>
                    <Col xs={24} md={12}>
                        <FieldsDropdown />
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name='SMSMobile'>
                            <Input placeholder='Mobile Number' />
                        </Form.Item>
                        <Form.Item name='WhatsAppMobile'>
                            <Input placeholder='WhatsApp Number' />
                        </Form.Item>
                        <Form.Item name='FieldA'>
                            <Input placeholder='Field A' />
                        </Form.Item>
                        <Form.Item name='FieldB'>
                            <Input placeholder='Field B' />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        )
    }

    const tabitems = [
        {
            key: 'Address',
            label: 'Address',
            children: <Address />,
        },
        {
            key: 'Tax Info',
            label: 'Tax Info',
            children: <TaxInfo />,
        },
        {
            key: 'Terms',
            label: 'Terms',
            children: <Terms />,
        },
        {
            key: 'Bank',
            label: 'Bank',
            children: <Bank />,
        },
        {
            key: 'Notes',
            label: 'Notes',
            children: <Notes />,
        },
        {
            key: 'Additional Fields',
            label: 'Additional Fields',
            children: <Fields />,
        },
    ];

    const onFinish = (value) => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            FYear: FYear,
            CompanyID: CompanyID,
            AccountCode: '',
            ...value,
            IsFiler: Filer,
            IsCustomer: Supplier,
            OpeningDate: OpenDate,
        }
        let url;
        if (props && props.SupplierID) {
            url = 'Supplier/UpdateSupplier';
            data.ID = props.SupplierID;
        }
        else {
            url = 'Supplier/AddSupplier';
        }

        // console.log(data);

        let api_config = {
            method: "post",

            url: Config.base_url + url,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    message.success(response.data.status_message);
                    setLoading(false);
                    formMain.resetFields();
                    props.handleOk();
                    window.location.reload();
                } else {
                    message.error(response.data.status_message);
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    };

    return (
        <>

            <Modal
                width={'800px'}
                open={props.show}
                title="Supplier Information"
                onOk={props.handleOk}
                onCancel={props.handleCancel}
                footer={null}

            >
                {/* <Skeleton active /> */}
                <Form layout="vertical" form={formMain} onFinish={onFinish}>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={10}>
                            <Form.Item name='BusinessName' label="Business Name" required>
                                <Input />
                            </Form.Item>
                            <Form.Item name="Email" label="Email"
                                rules={[
                                    {
                                        type: 'email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="AccountNo" label="Account No.">
                                <Input readOnly disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={14}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={4}>
                                    <Form.Item name="Title" label="Title">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={10}>
                                    <Form.Item name="FirstName" label="First Name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={10}>
                                    <Form.Item name="LastName" label="Last Name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Mobile" label="Mobile">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Phone" label="Phone">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item name="Website" label="Website">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="Address" items={tabitems} />
                    <Row gutter={[24, 0]} justify='end'>
                        <Col xs={24} md={4}>
                            <Button type="primary" block htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        </>
    );
};

export default AddSupplierModal