import React, { useState, useEffect } from 'react';

import Logo from "../../assets/images/logo/white-v.svg";
import { NavLink } from 'react-router-dom';
import { Form, Button, Input, message } from 'antd';
import { Link, useNavigate } from "react-router-dom";

import Config from '../../Config';
import axios from "axios";


function Login(props) {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const handleSubmit = (FormData) => {
        setLoading(true);
        const data = {
            ...FormData
        }

        console.log(data);
        var api_config = {
            method: 'post',
            url: Config.base_url + 'Users/SignIn',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log("login response", response.data)

                if (response.data.status_code == 1) {
                    
                    localStorage.setItem("AccessKey", response.data.AccessKey);
                    localStorage.setItem("ID", response.data.ID);
                    localStorage.setItem("Full_Name", response.data.Full_Name);
                    localStorage.setItem("Email_Address", response.data.Email_Address);
                    localStorage.setItem("Mobile_No", response.data.Mobile_No);
                    localStorage.setItem("DefaultFYear", response.data.FYear);

                    if(response.data.Email_Verified == false) {
                        navigate('/verify-email');
                    }
                    else {
                        navigate('/company');
                    }
                    setLoading(false);



                    //
                }
                else {
                    message.error(response.data.status_message);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log("response", error)
            });
    }


    return (
        <div className='auth-wrap'>
            <div className='left-col'>
                <div>
                    <img src={Logo} />
                    <h3>Streamline Your Finances: Welcome to Effortless Invoicing & Accounting!</h3>
                </div>
            </div>
            <div className='right-col'>
                <div className='header'>
                    <p>Don’t have an account?</p>
                    <NavLink to="/signup">
                        <Button>Register Now</Button>
                    </NavLink>
                </div>
                <div className='auth-form-wrap'>
                    <div className='section-title' style={{ marginBottom: "50px" }}>
                        <h2>Welcome Back!</h2>
                        <p>Unlock Seamless Financial Management with Every Sign-In.</p>
                    </div>

                    <Form
                        layout='vertical'
                        size='large'
                        className='form-default'
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            label="Email Address"
                            name="User_Email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="Password"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading} block>LOGIN</Button>
                    </Form>


                </div>
            </div>
        </div>
    );
}

export default Login;