
import React, { useState, useEffect } from 'react';
import AccountsMenu from './AccountsMenu';
import { PlusOutlined } from "@ant-design/icons";

import { ArrowLeftIcon } from "@heroicons/react/24/solid";

import { NavLink, useNavigate, useParams } from "react-router-dom";

import { Card, Col, Form, Input, Row, Radio, Select, Button, message, Skeleton } from 'antd';
import axios from "axios";

import Config from '../../Config';
import { useForm } from 'antd/es/form/Form';


function EditAccount(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const FYear = localStorage.getItem("DefaultFYear");
    const CompanyID = localStorage.getItem("CompanyID");
    const [Accounts, setAccounts] = useState([]);
    const [OpBalanceID, setOpBalanceID] = useState(0);

    let navigate = useNavigate();
    let params = useParams();
    const [AccountID, setAccountID] = useState(params.id);

    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingSaveAccount, setLoadingSaveAccount] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: AccountID,
            CompanyID: CompanyID,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Accounts/GetAccountByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log(data);

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);

                if (response.data.status_code == 1) {
                    setAccounts(response.data.Account);
                    setOpBalanceID(response.data.Account.OpBalanceID);
                    setLoading(false);
                    form.setFieldsValue(response.data.Account);
                }


            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);




    const handleSubmit = (FormData) => {


        setLoadingSaveAccount(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            CompanyID: CompanyID,
            OpBalanceID: OpBalanceID,
            Amount: FormData.Amount,
            AmountType: FormData.OpeningBalanceType,
            Account_Description: FormData.Account_Description,
            Budget_Allocation: FormData.Budget_Allocation,

        };

        // console.log("data",data);

        var api_config = {
            method: "post",
            url: Config.base_url + "Accounts/UpdateOpeningBalance",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setLoadingSaveAccount(false);
                    form.resetFields();
                    message.success(response.data.status_message);
                    navigate("/accounts/manage");
                } else {
                    message.error(response.data.status_message);
                }

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);

            });


    };


    return (
        <>
            <div className="sub-menu-wrap">
                <h5>Accounts</h5>
                <AccountsMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">
                            <NavLink to="/accounts/manage"><ArrowLeftIcon /></NavLink>
                            Edit Account
                        </h3>

                    </div>
                    {
                        loading ?
                            <>
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active />
                            </>
                            :
                            <>
                                <Form
                                    layout="vertical"
                                    className="form-compact"
                                    onFinish={handleSubmit}
                                    scrollToFirstError={true}
                                    form={form}
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 16,
                                    }}
                                >
                                    <Form.Item label="Account Code" name='Account_Code'>
                                        <Input readOnly />
                                    </Form.Item>
                                    <Form.Item label="Year" name='Year'>
                                        <Input readOnly />
                                    </Form.Item>
                                    <Form.Item name="Account_Description" label="Account Name">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Opening Balance" name='Amount'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Balance Type" name='OpeningBalanceType'>
                                        <Select
                                            options={[
                                                {
                                                    value: 'Debit',
                                                    label: 'Debit',
                                                },
                                                {
                                                    value: 'Credit',
                                                    label: 'Credit',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Budget Allocation" name='Budget_Allocation'>
                                        <Input />
                                    </Form.Item>
                                    <Button onClick={handleSubmit} type="primary" htmlType="submit" size="large" loading={loadingSaveAccount}>
                                        Save
                                    </Button>
                                </Form>
                            </>
                    }
                </div>
            </div>

        </>
    );
}

export default EditAccount;