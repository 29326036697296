import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';


import { ArrowRightEndOnRectangleIcon, CurrencyDollarIcon, ShoppingCartIcon, TagIcon, ChartPieIcon,  Cog6ToothIcon, HomeIcon, UsersIcon, UserGroupIcon, TruckIcon, TicketIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline'

function LeftMenu() {
    const currentLocation = useLocation();

    const isMenuItemActive = (path) => {
        return currentLocation.pathname.startsWith(path);
    };

    const handleSignOut = () => {
        localStorage.clear();
        window.location.href = "/login";
    }

    return (
        <>


            <div className="left-side">
                <ul className="side-bar">
                    <li>
                        <NavLink to="/dashboard" className={isMenuItemActive('/dashboard') ? 'active' : ''}>
                            <HomeIcon />
                            <span>Dashboard</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/accounts/manage" className={isMenuItemActive('/accounts') ? 'active' : ''}>
                            <UsersIcon />
                            <span>Accounts</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/bank/manage" className={isMenuItemActive('/bank') ? 'active' : ''}>
                            <BuildingLibraryIcon />
                            <span>Bank</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/payroll/employee/manage" className={isMenuItemActive('/payroll') ? 'active' : ''}>
                            <CurrencyDollarIcon />
                            <span>Payroll</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/accounts/manage-accounts" className={isMenuItemActive('/accounts') ? 'active' : ''}>
                            <ShoppingCartIcon />
                            <span>Purchase</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/accounts/manage-accounts" className={isMenuItemActive('/accounts') ? 'active' : ''}>
                            <UsersIcon />
                            <span>Production</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/accounts/manage-accounts" className={isMenuItemActive('/accounts') ? 'active' : ''}>
                            <ChartPieIcon />
                            <span>Sales</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/customer/manage" className={isMenuItemActive('/customer') ? 'active' : ''}>
                            <UserGroupIcon />
                            <span>Customer</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/supplier/manage" className={isMenuItemActive('/supplier') ? 'active' : ''}>
                            <TruckIcon />
                            <span>supplier</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/bill/manage" className={isMenuItemActive('/bill') ? 'active' : ''}>
                            <TicketIcon />
                            <span>Bill</span>
                        </NavLink>
                    </li>
                    
                    <li>
                        <NavLink to="/security/change-password" className={isMenuItemActive('/security') ? 'active' : ''}>
                            <Cog6ToothIcon />
                            <span>Settings</span>
                        </NavLink>

                    </li>
                    <li>
                        <NavLink to="/#" onClick={handleSignOut}>
                            <ArrowRightEndOnRectangleIcon />
                            <span>Sign Out</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default LeftMenu
