import { Form, Input, Select, Table, Button, Dropdown, Space, Menu, Popconfirm, message, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, DownloadOutlined, UploadOutlined, MoreOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { ChevronDownIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { NavLink } from "react-router-dom";
import AccountsMenu from "./AccountsMenu";
import Config from "../../Config";
import axios from "axios";

function ManageAccounts(props) {


  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [loading, setLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [OrderBy, setOrderBy] = useState("");
  const [AccountID, setAccountID] = useState("");
  const [AccountCode, setAccountCode] = useState("");
  const [Level, setLevel] = useState("0");

  const [IsDeleted, setIsDeleted] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Manage Accounts";

    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      CompanyID: CompanyID,
      FYear: FYear,
      pageNo: pageNo,
      pageSize: pageSize,
      AccountLevel: Level,
      AccountName: AccountID,
      OrderBy: OrderBy,
      AccountCode: AccountCode,
    };

    // console.log(data);
    var api_config = {
      method: "post",
      url: Config.base_url + "Accounts/GetAccountsByYear",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        setListOfRecords(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, Level, IsDeleted, AccountID, OrderBy, AccountCode]);


  const handleFilters = (formData) => {
    if (formData['Level'] != undefined) {
      setLevel(formData['Level']);
    }
    else if (formData['AccountID'] != undefined) {
      setAccountID(formData['AccountID']);
    }
    else if (formData['OrderBy'] != undefined) {
      setOrderBy(formData['OrderBy']);
    }
    else if (formData['AccountCode'] != undefined) {
      setAccountCode(formData['AccountCode']);
    }
    else {

    }
  }



  const columns = [
    {
      title: 'Sr#',
      dataIndex: '',
      key: 'SR',
      render: (_, record, index) => (
        index + 1 + pageSize * (pageNo - 1)
      )
    },
    {
      title: 'Account Name',
      dataIndex: 'Account_Description',
      key: 'Account_Description',
    },
    {
      title: 'Account Code',
      dataIndex: 'Account_Code',
      key: 'Account_Code',
    },

    {
      title: 'Level',
      dataIndex: 'iLevel',
      key: 'iLevel',
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, record) => (
        record.iLevel > 1 &&
        <div className="table-actions">


          <NavLink className={'primary'} to={`/accounts/edit-account/${record.ID}`}>
            <EditOutlined />
          </NavLink>

          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this account?"
            onConfirm={(e) => deleteAccount(record.ID)}
            okText="Yes"
            cancelText="No"
          >

            <DeleteOutlined />

          </Popconfirm>

        </div>
      )
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };


  const deleteAccount = (ID) => {

    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };

    var api_config = {
      method: "post",
      url: Config.base_url + "Accounts/DeleteAccount",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          setLoading(false);
          setIsDeleted(true);
        }
        else {
          message.error(response.data.status_message);
          setLoading(false);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  };
  const onReset = () => {
    form.resetFields();
    setLevel('0');
    setAccountCode('');
    setAccountID('');
  };

  return (
    <>

      <div className="sub-menu-wrap">
        <h5>Accounts</h5>
        <AccountsMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Manage Accounts</h3>
            <div className="header-actions">
              <NavLink to="/accounts/import">
                <Button type="dashed" icon={<DownloadOutlined />}>
                  Import
                </Button>
              </NavLink>
              <Button type="dashed" icon={<UploadOutlined />}>
                Export
              </Button>

              <NavLink to="/accounts/add-account">
                <Button type="primary" icon={<PlusOutlined />}>
                  New
                </Button>
              </NavLink>
            </div>

          </div>
          <div className="filters-wrap">
            <Form
              onFinish={handleFilters}
              form={form}
            >
              <Form.Item name="OrderBy">
                <Select
                  placeholder="Order By"
                  style={{ width: 120 }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: "AccountCode",
                      label: "Account Code"
                    },
                    {
                      value: "AccountName",
                      label: "Account Name",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="Level">
                <Select
                  placeholder="All Level"
                  style={{ width: 120 }}
                  options={[
                    {
                      value: "0",
                      label: "All Levels"
                    },
                    {
                      value: "1",
                      label: "Level 1",
                    },
                    {
                      value: "2",
                      label: "Level 2",
                    },
                    {
                      value: "3",
                      label: "Level 3",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="AccountID">

                <Input
                  placeholder="Account Name" />
              </Form.Item>
              <Form.Item name="AccountCode">

                <Input
                  placeholder="Account No" />
              </Form.Item>


              <Button htmlType="submit">Filter</Button>
              <Button htmlType="button" onClick={onReset} type="link">Reset</Button>
            </Form>
          </div>

          <Table columns={columns} dataSource={ListOfRecords} size="small" loading={loading} pagination={false} />

          <div style={{ margin: "50px 0" }}>
            {totalRecords > 10 && (
              <>
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={pageNo}
                  pageSize={pageSize}
                  total={totalRecords}
                  onChange={page => setPageNo(page)} />
              </>
            )}
          </div>

        </div>
      </div>

    </>
  );
}

export default ManageAccounts;
