import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/css/style.css";
import Login from "./components/Onboarding/Login";
import Signup from "./components/Onboarding/Signup";
import Welcome from "./components/Onboarding/Welcome";
import RegisterCompany from "./components/Onboarding/RegisterCompany";
import VerifyEmail from "./components/Onboarding/VerifyEmail";
import Dashboard from "./components/Dashboard/Dashboard";
import Main from "./components/Layout/Main";
import SelectCompany from "./components/Onboarding/SelectCompany";
import { ConfigProvider, theme } from "antd";
import ChangeCompany from "./components/Company/ChangeCompany";
import SendInvitation from "./components/Setting/SendInvitation";
import ForgetPassword from "./components/Onboarding/ForgetPassword";
import SettingDashboard from "./components/Setting/SettingDashboard";
import ChangePassword from "./components/Profile/ChangePassword";
import UpdateProfile from "./components/Profile/UpdateProfile";
import AddAccount from "./components/Accounts/AddAccount";
import ManageAccounts from "./components/Accounts/ManageAccounts";
import AddVouchers from "./components/Accounts/Voucher/AddVouchers";
import ManageEmployees from "./components/Payroll/Employee/ManageEmployees";
import ManageVouchers from "./components/Accounts/Voucher/ManageVouchers";
import TrialBalanceLevelWise from "./components/Accounts/TrialBalanceLevelWise";
import TrialBalanceHeadWise from "./components/Accounts/TrialBalanceHeadWise";
import BudgetHeadWise from "./components/Accounts/BudgetHeadWise";
import ReceiptPaymentAccount from "./components/Accounts/ReceiptPaymentAccount";
import OpeningBalances from "./components/Accounts/OpeningBalances";
import AddEmployee from "./components/Payroll/Employee/AddEmployee";
import Designation from "./components/Payroll/Employee/Designation";
import SalaryType from "./components/Payroll/Salary/SalaryType";
import BanksalaryList from "./components/Payroll/Salary/BankSalaryList";
import Createsalary from "./components/Payroll/Salary/CreateSalary";
import IncreaseDecreaseSalary from "./components/Payroll/Salary/IncreaseDecreaseSalary";
import Viewreports from "./components/Payroll/Reports/ViewReports";
import Zeroreports from "./components/Payroll/Reports/ZeroReports";
import SummarySheet from "./components/Payroll/Reports/SummarySheet";
import AddArrears from "./components/Payroll/ArrearLeave/AddArrears";
import ManageLeaves from "./components/Payroll/ArrearLeave/ManageLeaves";
import ManageLoanDeduction from "./components/Payroll/Deductions/ManageLoanDeduction";
import ManageOtherDeduction from "./components/Payroll/Deductions/ManageOtherDeduction";
import ImportAccounts from "./components/Accounts/ImportAccounts";
import EditVouchers from "./components/Accounts/Voucher/EditVouchers";
import EditAccount from "./components/Accounts/EditAccount";
import ManageCustomer from "./components/Customer/ManageCustomer";
import DropdownTest from "./components/Customer/FieldsDropdown";
import ImportCustomer from "./components/Customer/ImportCustomer";
import ManageSupplier from "./components/Suppliers/ManageSupplier";
import ImportSupplier from "./components/Suppliers/ImportSupplier";
import ManageBill from "./components/Bill/ManageBill";
import ImportBill from "./components/Bill/ImportBill";
import SupplierBill from "./components/Suppliers/Bill/SupplierBill";
import ManageBank from "./components/Bank/ManageBank";
import ImportBank from "./components/Bank/ImportBank";
import AddBankPayments from "./components/Bank/AddBankPayments";
import AddWHTPayments from "./components/Bank/AddWHTPayments";
import AddBankReceipts from "./components/Bank/AddBankReceipts";
import ManageReceipts from "./components/Bank/ManageReceipts";
import AddTransfers from "./components/Bank/AddTransfers";
import ManageTransfers from "./components/Bank/ManageTransfers";
import ComponentTestPage from "./ComponentTestPage";
import ReconcileStatementBalance from "./components/Bank/ReconcileStatementBalance";
import ManageReconcile from "./components/Bank/ManageReconcile";







function Index() {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <ConfigProvider
      theme={{
        // algorithm: darkAlgorithm,
        //type: 'dark',

        components: {
          Button: {
            primaryColor: "#fff",
            fontWeight: "600",
            contentFontSize: 16,
            defaultBorderColor: "#2046CF",
            defaultColor: "#2046CF"

          },
          Form: {
            labelColor: "#68757D",
            labelFontSize: 14
          }
        },

        token: {
          // Seed Token
          colorPrimary: "#2046CF",
          //borderRadius: 12,

          // Alias Token
          //colorBgContainer: '#324F94',
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/company" element={<SelectCompany />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/company-register" element={<RegisterCompany />} />

          <Route path="/" element={<Main />}>
            <Route path="/test-page" element={<ComponentTestPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/register-company" element={<RegisterCompany />} />
            <Route path="/change-company" element={<ChangeCompany />} />
            <Route path="/send-invitation" element={<SendInvitation />} />
            <Route path="/setting" element={<SettingDashboard />} />

            {/* accounts */}
            <Route path="/accounts/add-account" element={<AddAccount />} />
            <Route path="/accounts/edit-account/:id" element={<EditAccount />} />
            <Route path="/accounts/manage" element={<ManageAccounts />} />
            <Route path="/accounts/opening-balances" element={<OpeningBalances />} />
            <Route path="/accounts/trial-balance-level-wise" element={<TrialBalanceLevelWise />} />
            <Route path="/accounts/trial-balance-Head-wise" element={<TrialBalanceHeadWise />} />
            <Route path="/accounts/Budget-Head-wise" element={<BudgetHeadWise />} />
            <Route path="/accounts/Receipt-Payment-Account" element={<ReceiptPaymentAccount />} />
            <Route path="/accounts/import" element={<ImportAccounts />} />







            <Route path="/profile" element={<UpdateProfile />} />
            <Route path="/change-password" element={<ChangePassword />} />

            {/* Bank */}

            <Route path="/bank/import" element={<ImportBank />} />

            <Route path="/bank/manage" element={<ManageBank />} />
            <Route path="/bank/add-bank-payment" element={<AddBankPayments />} />
            <Route path="/bank/wht-bank-payment" element={<AddWHTPayments />} />


            <Route path="/bank/receipts" element={<ManageReceipts />} />
            <Route path="/bank/add-bank-receipts" element={<AddBankReceipts />} />

            <Route path="/bank/transfers" element={<ManageTransfers />} />
            <Route path="/bank/add-transfers" element={<AddTransfers />} />
            
            <Route path="/bank/reconcile" element={<ManageReconcile />} />
            <Route path="/bank/reconcile-statement-balance" element={<ReconcileStatementBalance />} />


            {/*  Voucher */}

            <Route path="/vouchers" element={<ManageVouchers />} />
            <Route path="/vouchers/add" element={<AddVouchers />} />
            <Route path="/vouchers/edit/:id" element={<EditVouchers />} />

            {/*  Customer */}

            <Route path="/customer/manage" element={<ManageCustomer />} />
            <Route path="/customer/import" element={<ImportCustomer />} />

            {/* Supplier */}

            <Route path="/supplier/manage" element={<ManageSupplier />} />
            <Route path="/supplier/import" element={<ImportSupplier />} />

            {/* Bill */}

            <Route path="/bill/manage" element={<ManageBill />} />
            <Route path="/bill/import" element={<ImportBill />} />

            <Route path="/supplier/supplier-bill/add-supplier-bill" element={<SupplierBill />} />

            {/* Payroll */}

            <Route path="/payroll/employee/manage" element={<ManageEmployees />} />
            <Route path="/payroll/employee/add" element={<AddEmployee />} />
            <Route path="/payroll/employee/designations" element={<Designation />} />
            <Route path="/payroll/salary/salary-type" element={<SalaryType />} />
            <Route path="/payroll/salary/bank-salary-list" element={<BanksalaryList />} />
            <Route path="/payroll/salary/create-salary" element={<Createsalary />} />
            <Route path="/payroll/salary/increase/decrease-salary" element={<IncreaseDecreaseSalary />} />
            <Route path="/payroll/reports/view-reports" element={<Viewreports />} />
            <Route path="/payroll/reports/zero-reports" element={<Zeroreports />} />
            <Route path="/payroll/reports/summary-sheet" element={<SummarySheet />} />
            <Route path="/payroll/ArrearLeave/AddArrears" element={<AddArrears />} />
            <Route path="/payroll/ArrearLeave/mange-leave" element={<ManageLeaves />} />
            <Route path="/payroll/Deductions/manage-loan-deduction" element={<ManageLoanDeduction />} />
            <Route path="/payroll/Deductions/manage-other-deduction" element={<ManageOtherDeduction />} />







          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
