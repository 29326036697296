import React, { useState } from 'react';
import {
    FileDoneOutlined,
    CreditCardOutlined,
    CalculatorOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    RightOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { NavLink } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}
const items = [
   
   
    getItem(<NavLink to="/accounts/manage">Manage Accounts</NavLink>, '2', <TeamOutlined />),
    getItem(<NavLink to="/vouchers/">Vouchers</NavLink>, 'sub1', <FileDoneOutlined />),
    getItem(<NavLink to="/accounts/opening-balances">Opening Balances</NavLink>, 'sub2', <CreditCardOutlined />),
    getItem(<NavLink to="/accounts/trial-balance-level-wise">Trial Balance Level Wise</NavLink>, '9', <FileOutlined />),
    getItem(<NavLink to="/accounts/trial-balance-Head-wise" >Trial Balance Head Wise</NavLink>, '10', <FileOutlined />),
    getItem(<NavLink to="/accounts/Budget-Head-wise" >Budget Head Wise</NavLink>, '11', <FileOutlined />),
    getItem(<NavLink to="/accounts/Receipt-Payment-Account" >Receipt Payment Account</NavLink>, '12', <CalculatorOutlined />),
];
const AccountsMenu = () => {
   
    return (
        <Menu id='ant-sidemenu' defaultSelectedKeys={['0']} mode="inline" items={items} />
    );
};
export default AccountsMenu;