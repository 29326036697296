import React, { useState, useRef } from 'react'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Button, Col, DatePicker, Form, Input, Row, Select, message, Divider, Space, Collapse } from 'antd'
import dayjs from 'dayjs';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { calculateColumnTotal } from '../../Shared/Utility';
import Config from '../../../Config';
import axios from 'axios';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import AddSupplierModal from '../AddSupplierModal';

const SupplierBill = () => {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");

    const [VoucherNo, setVoucherNo] = useState("");
    const [VoucherDate, setVoucherDate] = useState(dayjs());
    const [VoucherRemarks, setVoucherRemarks] = useState("");

    // voucher
    const [VoucherTypeID, setVoucherTypeID] = useState(0);
    const [ExpenseList, setExpenseList] = useState([]);


    const [loadingVoucherTypes, setloadingVoucherTypes] = useState(false);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [loadingAddVoucher, setLoadingAddVoucher] = useState(false);

    // account
    const [Accounts, setAccounts] = useState([]);
    const [AccountsDropdown, setAccountsDropdown] = useState([]);
    const [AccountCode, setAccountCode] = useState("");
    const [AccountDesc, setAccountDesc] = useState("");

    const [loading, setLoading] = useState(false);
    const [SupplierLoading, setSupplierLoading] = useState(false);
    const [SupplierID, setSupplierID] = useState("");
    const [open, setOpen] = useState(false);

    const navigator = useNavigate();
    const [form] = Form.useForm();
    const [formMain] = Form.useForm();
    const [OpenDate, setOpenDate] = useState(null);

    const handleAccountChange = (value, account) => {
        setAccountCode(account.code);
        setAccountDesc(account.desc);

        form.setFieldValue('Description', account.desc);
        form.setFieldValue('Code', account.code);
    }
    const handleAddItem = (formData) => {
        const newItem = {
            Seq: ExpenseList.length,
            ...formData
        };

        setExpenseList([...ExpenseList, newItem]);
        form.resetFields();
    }
    const handleSaveVoucher = () => {

        setLoadingAddVoucher(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            FYear: FYear,
            VoucherTypeID: VoucherTypeID,
            Voucher_number: VoucherNo,
            Voucher_date: VoucherDate,
            Remarks: VoucherRemarks,
            ListofVoucherDetail: ExpenseList,
        };



        var api_config = {
            method: "post",
            url: Config.base_url + "Voucher/AddNewVoucher",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {

                    message.success(response.data.status_message);
                    setLoadingAddVoucher(false);
                    navigator(`/vouchers/`);
                } else {
                    message.error(response.data.status_message);
                    setLoadingAddVoucher(false);
                }
            })
            .catch(function (error) {

            });
    };
    const handleDeleteExpense = (index) => {
        const updatedFormData = [...ExpenseList];

        // index, number of items to be removed, new elements to be added
        updatedFormData.splice(index, 1);
        setExpenseList(updatedFormData);
    };
    const handleDeleteProducts = (index) => {
        const updatedFormData = [...ExpenseList];

        // index, number of items to be removed, new elements to be added
        updatedFormData.splice(index, 1);
        setExpenseList(updatedFormData);
    };
    const onFinish = (value) => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            FYear: FYear,
            CompanyID: CompanyID,
            AccountCode: '',
            ...value,
        }
        let url;
        // if (props.CustomerID === undefined) {
        //     url = 'Customer/AddCustomer'
        // }
        // else {
        //     url = 'Customer/UpdateCustomer';
        //     data.ID = props.CustomerID;
        // }

        // console.log(data);

        let api_config = {
            method: "post",

            url: Config.base_url + url,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    message.success(response.data.status_message);
                    setLoading(false);
                    formMain.resetFields();
                } else {
                    message.error(response.data.status_message);
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    };
    const [FieldOneItems, setFieldOneItems] = useState([]);
    const [FieldOneName, setFieldOneName] = useState('');
    const FieldOneRef = useRef(null);
    const onFieldOneNameChange = (event) => {
        setFieldOneName(event.target.value);
    };
    const addFieldOneItem = (e) => {
        e.preventDefault();
        setFieldOneItems([...FieldOneItems, FieldOneName]);
        setFieldOneName('');
        setTimeout(() => {
            FieldOneRef.current?.focus();
        }, 0);
    };

    const handleOk = (FormData) => {
        setLoading(true);
        setOpen(false);
        setLoading(false);
    };
    const handleCancel = () => {
        setOpen(false);

    };

    const SupplierDetail = () => {
        return (
            <>
                <Row gutter={[24, 0]}>
                    <Col xs={24} md={10}>
                        <Form.Item name='Supplie' label='Supplier' required>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Supplie"
                                dropdownRender={(menufieldone) => (
                                    <>
                                        {menufieldone}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            {/* <Input
                                                        placeholder="Enter Name"
                                                        ref={FieldOneRef}
                                                        value={FieldOneName}
                                                        onChange={onFieldOneNameChange}
                                                        onKeyDown={(e) => e.stopPropagation()}
                                                    /> */}
                                            <Button type="text" icon={<PlusOutlined />} onClick={() => setOpen(true)}>
                                                Add Field
                                            </Button>
                                        </Space>
                                    </>
                                )}
                                options={FieldOneItems.map((fieldoneitem) => ({
                                    label: fieldoneitem,
                                    value: fieldoneitem,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="Address" label="Address">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={14}>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item name="Bill No." label="Bill No.">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="Days" label="Days" required>
                                    <DatePicker
                                        defaultValue={OpenDate == null ? (dayjs()) : dayjs(OpenDate, "YYYY-MM-DD")}
                                        style={{ width: "100%" }} onChange={(value) => setOpenDate(value)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="Term Days" label="Term Days">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="Due" label="Due">
                                    <DatePicker
                                        defaultValue={OpenDate == null ? (dayjs()) : dayjs(OpenDate, "YYYY-MM-DD")}
                                        style={{ width: "100%" }} onChange={(value) => setOpenDate(value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    const ExpensesDetail = () => {
        return (
            <>
                <Form layout="horizontal" form={formMain} onFinish={onFinish}>
                    <Row>
                        <Col xs={24} md={24}>
                            <Form onFinish={handleAddItem} form={form}>
                                <div className="ant-table-custom table-compact">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Expense</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Disc %</th>
                                                <th scope="col">Discount</th>
                                                <th scope="col">Tax Rate</th>
                                                <th scope="col">Sale Tax</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ExpenseList.map((item, index) => (
                                                <tr key={index}>
                                                    <td></td>
                                                    <td>{item.Description}</td>
                                                    <td>{item.Code}</td>
                                                    <td>{item.Dr_Amt}</td>
                                                    <td>{item.Cr_Amt}</td>
                                                    <td>{item.Remarks}</td>
                                                    <td>
                                                        <ul className="inline-action">
                                                            <li>
                                                                <Link to={`#/`} onClick={() => handleDeleteExpense(index)} className="red">
                                                                    <DeleteOutlined />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}

                                            <tr className="table-form">
                                                <td>
                                                    <Form.Item name="AccountID" rules={[{ required: false, },]}>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            placeholder="Expense"
                                                            variant="borderless"
                                                            style={{
                                                                width: 250,
                                                            }}
                                                            onChange={handleAccountChange}
                                                            loading={loadingAccounts}
                                                            options={AccountsDropdown}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Description">
                                                        <Input placeholder="Description" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Amount">
                                                        <Input placeholder="Amount" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Disc">
                                                        <Input placeholder="Disc %" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Discount">
                                                        <Input placeholder="Discount" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Tax Rate">
                                                        <Input placeholder="Tax Rate" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Sale Tax">
                                                        <Input placeholder="Sale Tax" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Button icon={<PlusCircleIcon />} htmlType="submit"></Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Form >
            </>
        )
    }
    const ProductDetail = () => {
        return (
            <>
                <Form layout="horizontal" form={formMain} onFinish={onFinish}>
                    <Row>
                        <Col xs={24} md={24}>
                            <Form onFinish={handleAddItem} form={form}>
                                <div className="ant-table-custom table-compact">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Product / Services</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Unit</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Rate</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Disc %</th>
                                                <th scope="col">Discount</th>
                                                <th scope="col">Tax Rate</th>
                                                <th scope="col">Sale Tax</th>
                                                <th scope="col">Net</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ExpenseList.map((item, index) => (
                                                <tr key={index}>
                                                    <td></td>
                                                    <td>{item.Description}</td>
                                                    <td>{item.Code}</td>
                                                    <td>{item.Dr_Amt}</td>
                                                    <td>{item.Cr_Amt}</td>
                                                    <td>{item.Remarks}</td>
                                                    <td>
                                                        <ul className="inline-action">
                                                            <li>
                                                                <Link to={`#/`} onClick={() => handleDeleteProducts(index)} className="red">
                                                                    <DeleteOutlined />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}

                                            <tr className="table-form">
                                                <td>
                                                    <Form.Item name="AccountID" rules={[{ required: false, },]}>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            placeholder="Product / Services"
                                                            variant="borderless"
                                                            style={{
                                                                width: 250,
                                                            }}
                                                            onChange={handleAccountChange}
                                                            loading={loadingAccounts}
                                                            options={AccountsDropdown}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Description">
                                                        <Input placeholder="Description" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Unit">
                                                        <Input placeholder="Unit" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Qty">
                                                        <Input placeholder="Qty" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Rate">
                                                        <Input placeholder="Rate" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Amount">
                                                        <Input placeholder="Amount" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Disc %">
                                                        <Input placeholder="Disc %" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Discount">
                                                        <Input placeholder="Discount" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Tax Rate">
                                                        <Input placeholder="Tax Rate" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Sale Tax">
                                                        <Input placeholder="Sale Tax" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Net">
                                                        <Input placeholder="Net" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Button icon={<PlusCircleIcon />} htmlType="submit"></Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Form >
            </>
        )
    }
    const AddPayment = () => {
        return (
            <>
                <Row>
                    <Col xs={24} md={24}>
                        <Form onFinish={handleAddItem} form={form}>
                            <div className="ant-table-custom table-compact">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Bank</th>
                                            <th scope="col">Mode</th>
                                            <th scope="col">Ref. No.</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ExpenseList.map((item, index) => (
                                            <tr key={index}>
                                                <td></td>
                                                <td>{item.Description}</td>
                                                <td>{item.Code}</td>
                                                <td>{item.Dr_Amt}</td>
                                                <td>{item.Cr_Amt}</td>
                                                <td>{item.Remarks}</td>
                                            </tr>
                                        ))}

                                        <tr className="table-form">
                                            <td style={{ borderBottom: "transparent" }}>
                                                <DatePicker
                                                    defaultValue={OpenDate == null ? (dayjs()) : dayjs(OpenDate, "YYYY-MM-DD")}
                                                    style={{ width: "100%" }} onChange={(value) => setOpenDate(value)} />
                                            </td>
                                            <td style={{ borderBottom: "transparent" }}>
                                                <Form.Item name="Bank" rules={[{ required: false, },]}>
                                                    <Select
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        placeholder="Bank"
                                                        variant="borderless"
                                                        style={{
                                                            width: 250,
                                                        }}
                                                        onChange={handleAccountChange}
                                                        loading={loadingAccounts}
                                                        options={AccountsDropdown}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td style={{ borderBottom: "transparent" }}>
                                                <Form.Item name="Mode" rules={[{ required: false, },]}>
                                                    <Select
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        placeholder="Mode"
                                                        variant="borderless"
                                                        style={{
                                                            width: 250,
                                                        }}
                                                        onChange={handleAccountChange}
                                                        loading={loadingAccounts}
                                                        options={AccountsDropdown}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td style={{ borderBottom: "transparent" }}>
                                                <Form.Item name="Ref. No.">
                                                    <Input placeholder="Ref. No." variant="borderless" />
                                                </Form.Item>
                                            </td>
                                            <td style={{ borderBottom: "transparent" }}>
                                                <Form.Item name="Amount">
                                                    <Input placeholder="Amount" variant="borderless" />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }
    return (
        <>
            <AddSupplierModal show={open} handleOk={handleOk} handleCancel={handleCancel} loading={SupplierLoading} SupplierID={SupplierID} />
            <div className="sub-menu-wrap">
                <h5>Bill</h5>
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">
                            <NavLink to="/vouchers/"><ArrowLeftIcon /></NavLink>Add Supplier Bill
                        </h3>
                    </div>
                    <Form layout="vertical" form={formMain} onFinish={onFinish}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Collapse
                                collapsible="header"
                                defaultActiveKey={['Supplier Detail']}
                                items={[
                                    {
                                        key: 'Supplier Detail',
                                        label: 'Supplier Detail',
                                        children: <SupplierDetail />,
                                    },
                                ]}
                            />
                            <Collapse
                                collapsible="header"
                                defaultActiveKey={['Expenses Detail']}
                                items={[
                                    {
                                        key: 'Expenses Detail',
                                        label: 'Expenses Detail',
                                        children: <ExpensesDetail />,
                                    },
                                ]}
                            />
                            <Collapse
                                collapsible="header"
                                defaultActiveKey={['ProductDetail']}
                                items={[
                                    {
                                        key: 'ProductDetail',
                                        label: 'Product Detail',
                                        children: <ProductDetail />,
                                    },
                                ]}
                            />
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Notes" label="Notes">
                                        <Input.TextArea rows={5} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={[24, 0]}>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="Sub Total" label="Sub Total">
                                                <Input disabled readOnly />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="Discount" label="Discount">
                                                <Input disabled readOnly />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="Sale Tax" label="Sale Tax">
                                                <Input disabled readOnly />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <h4>Total</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse
                                collapsible="header"
                                defaultActiveKey={['AddPayment']}
                                items={[
                                    {
                                        key: 'AddPayment',
                                        label: 'Add Payment',
                                        children: <AddPayment />,
                                    },
                                ]}
                            />
                        </Space>
                    </Form>



                </div>
            </div>
        </>
    )
}

export default SupplierBill