
import axios from 'axios';
import Config from '../../Config';
 

async function SendEmail(props) {
    const AccessKey = localStorage.getItem('AccessKey');
    const UserID = localStorage.getItem('ID');
   

    const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        TransactionType: props.TransactionType
    };
    ////console.log(data);

    var api_config = {
        method: 'post',
        url: Config.base_url + 'Security/SendAuthenticationCode',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios(api_config);
        ////console.log(response);
        if (response.data.status_code == 1) {
          return true;
        }
        else {
          return false;
        }
        // Additional logic or handling of the response
    } catch (error) {
        // Handle the error
    }

}

export default SendEmail;
