import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Input, Button, message, Space } from "antd";
import ProfileMenu from "./ProfileMenu";
import axios from "axios";
import Config from "../../Config";
import SendEmail from "../Shared/SendEmail";

function ChangePassword(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));

  const [loading, setLoading] = useState(false);
  const [codeloading, setcodeloading] = useState(false);

  // messages
  const [messageApi, contextHolder] = message.useMessage();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds

  useEffect(() => {
    document.title = "Change Password";
  }, []);

  const [authCode, setAuthCode] = useState("");
  const [authCodeValid, setAuthCodeValid] = useState(true); // To track if the code is valid
  const [OTP, setOTP] = useState("");

  const onFinish = (formData) => {
    //console.log("data", formData.NewPassword);
    setLoading(true);

    delete formData.ConfirmPassword;

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      //    AuthorizationCode:authCode,
      ...formData,
    };



    var api_config = {
      method: "post",
      url: Config.base_url + "Members/ChangePassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
        } else {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) { });
  };
  const handleSendCode = () => {
    const props = {
      TransactionType: "ChangePassword",
    };

    if (SendEmail(props)) {
      setIsButtonDisabled(true);

      messageApi.open({
        type: 'success',
        content: 'Authentication code has been sent',
      });
    }
    else {
      setIsButtonDisabled(false);

      messageApi.open({
        type: 'error',
        content: 'Fail to send Authentication code',
      });
    }



    // Enable the button after 1 minute
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 60000);
    setTimer(60);
  };

  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Profile</h5>
        <ProfileMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
        <div className="page-header">
          <h3 className="page-title">Change Password</h3>
          </div>
          <Row>
            
            <Col md={{ span: 8 }} xs={24}>
              <Card bordered={false}>
                <div className="profile-wrap">
                  <Form
                    name="basic"
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    className="form-default"
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Old Password"
                      name="OldPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please input your old password!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="New Password"
                      name="NewPassword"
                      dependencies={["NewPassword"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      name="ConfirmPassword"
                      dependencies={["NewPassword"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("NewPassword") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    {/* <Row gutter={20} align={'middle'}>
                                    <Col xs={24} md={18}>
                                        <Form.Item
                                            label="Email Authentication Code"
                                            name="AuthorizationCode"
                                            autoComplete={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter valid verification code!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="mt-3"
                                            block
                                            loading={codeloading}
                                            disabled={true} // Conditionally enable the button based on authCodeValid
                                        >
                                            Get Code
                                        </Button>
                                    </Col>
                                </Row> */}
                    <Form.Item
                      name="AuthorizationCode"
                      label="Authentication Code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Authentication Code",
                        },
                      ]}
                    >
                      <Space.Compact
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input />
                        <Button
                          type="default"
                          onClick={handleSendCode}
                          disabled={isButtonDisabled}
                        >
                          GET CODE
                        </Button>
                      </Space.Compact>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
