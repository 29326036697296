import React, { useState } from "react";

import Logo from "../../assets/images/logo/dark-h.svg";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { ChevronDownIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { UsersIcon, CurrencyDollarIcon, Cog8ToothIcon, TagIcon, ShoppingCartIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import { Button, Col, Form, Input, Row, Upload, Dropdown, Tooltip } from 'antd';
import SideMenu from "./SideMenu";
import LeftMenu from "./LeftMenu";



function Main(props) {



    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const FullName = localStorage.getItem("Full_Name");
    const CompanyName = localStorage.getItem("CompanyName");

    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.clear();
        navigate('/login');
    }


    const items = [
        {
            key: '1',
            label: (
                <a rel="noopener noreferrer" href="/profile">
                    Edit Profile
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <NavLink to="/change-company">
                    Change Company
                </NavLink>
            ),
        },
        {
            key: '3',
            label: (
                <a rel="noopener noreferrer" href="#" onClick={handleSignOut}>
                    Sign Out
                </a>
            ),
            // icon: <SmileOutlined />,
        },

    ];


    const getFirstChar = (str) => {
        const firstChars = str
            .split(" ")
            .map((word) => word[0])
            .join("");
        return firstChars;
    };



    const [collapse, setcollapse] = useState(false);

    const CollapseMenu = () => {
        setcollapse(!collapse);
        var admin_wrap = document.getElementsByClassName("admin-wrap");
        for (let i = 0; i < admin_wrap.length; i++) {
            admin_wrap[i].classList.toggle('show');
        }
    }

    const primaryColor = '#324F94'
    return (

        <>
            <main id="main">
                <header id="dashboard-header">
                    <div>
                        <div className="header-left">
                            <img src={Logo} />
                            <div className="working-company">
                                <div>
                                    {CompanyName}
                                </div>
                                <NavLink to="/register-company">
                                    <PlusCircleIcon />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <div className='button-user-in'>
                                <div className='short-name'>
                                    {getFirstChar(FullName)}
                                </div>
                                <span>{FullName}</span>
                                <ChevronDownIcon />
                            </div>
                        </Dropdown>
                    </div>
                </header>
                <div className="wrapper">
                    <LeftMenu />
                    <div className="right-side">
                        <Outlet />
                    </div>
                </div>
            </main>
        </>


        // <main id="main">

        //     <section className="layout-wrap">
        //         <SideMenu />
        //         <div className="layout-content">
        //             <Outlet />
        //         </div>
        //     </section>
        // </main>
    );
}

export default Main;