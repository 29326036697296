
import React, { useState, useEffect } from 'react';
import AccountsMenu from './AccountsMenu';
import { PlusOutlined } from "@ant-design/icons";

import { ArrowLeftIcon } from "@heroicons/react/24/solid";

import { NavLink } from "react-router-dom";

import { Card, Col, Form, Input, Row, Radio, Select, Button, message } from 'antd';
import axios from "axios";

import Config from '../../Config';
import { useForm } from 'antd/es/form/Form';


function AddAccount(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const FYear = localStorage.getItem("DefaultFYear");
    const CompanyID = localStorage.getItem("CompanyID");
    const [level, setLevel] = useState(1);
    const [Accounts, setAccounts] = useState([]);

    const [AccountCode, setAccountCode] = useState("");
    const [ParentAccountID, setParentAccountID] = useState(0);

    const [FirstAccountId, setFirstAccountId] = useState(0);
    const [SecondAccountId, setSecondAccountId] = useState(0);
    const [ThirdAccountId, setThirdAccountId] = useState(0);

    const [FirstLevelCode, setFirstLevelCode] = useState(0);
    const [SecondLevelCode, setSecondLevelCode] = useState(0);
    const [ThirdLevelCode, setThirdLevelCode] = useState(0);


    const [AccountLevel, setAccountLevel] = useState(0);
    const [AccountName, setAccountName] = useState("");
    const [AccountRemarks, setAccountRemarks] = useState("");


    // account level accounts arrays
    const [FirstLevelAccounts, setFirstLevelAccounts] = useState([]);
    const [SecondLevelAccounts, setSecondLevelAccounts] = useState([]);
    const [ThirdLevelAccounts, setThirLevelAccounts] = useState([]);

    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingSaveAccount, setLoadingSaveAccount] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            FYear: 2024,
            pageNo: 1,
            pageSize: 1000,
            pAccountID: ParentAccountID,
            CompanyID: CompanyID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Accounts/GetAccountsByYearWithDeleted',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(data);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);

                if (response.data.status_code == 1) {
                    setAccounts(response.data.ListofRecords);
                    setLoading(false);
                }


            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);

    useEffect(() => {
        if (!loading) {
            let level1 = Accounts.filter((item) => item.iLevel === 1);
            let level2 = Accounts.filter((item) => item.iLevel === 2);

            const level1Select = level1.map((item) => ({
                value: item.ID,
                label: item.Account_Description,
                code: item.Account_Code
            }));

            setFirstLevelAccounts(level1Select);


            if (level1.length === 0) {
                setFirstLevelCode(10);
                setAccountCode(10);
            }
            else {
                let lastItem = level1[level1.length - 1];
                setFirstLevelCode(parseInt(lastItem.Account_Code) + parseInt(10));
                setAccountCode(parseInt(lastItem.Account_Code) + parseInt(10));

            }



        }
    }, [Accounts]);






    const handleFirstChange = (value, account) => {

        if (account) {
            setAccountLevel(2);
            setFirstAccountId(value);
            setAccountName("");
            setFirstLevelCode(account.code);
            //setAccountCode(account.code);


            let level2 = Accounts.filter(
                (item) => item.iLevel == 2 && item.Account_Code.startsWith(account.code) && item.Account_Code.length == 5
            );

            //console.log(level2);

            if (level2.length == 0) {
                setSecondLevelCode(account.code + 101);
                setAccountCode(account.code + 101);
            }
            else {
                let lastItemOfLevel2 = level2[level2.length - 1];

                // console.log('last item', lastItemOfLevel2);

                let second_code = parseInt(lastItemOfLevel2.Account_Code) + parseInt(1);
                setSecondLevelCode(second_code);
                setAccountCode(second_code);
            }


            let level2Accounts = Accounts.filter(
                (item) => item.iLevel == 2 && item.Account_Code.startsWith(account.code)
            );

            

            const level2Select = level2Accounts.map((item) => ({
                value: item.ID,
                label: item.Account_Description,
                code: item.Account_Code
            }));


            setSecondLevelAccounts(level2Select);
        }
        else {
            setAccountName("");
            setAccountLevel(0);
        }

    }


    const handleSecondChange = (value, account) => {
        setAccountLevel(3);
        setSecondAccountId(value);
        setAccountName("");
        //setAccountCode(account.code);

        if (account.code == 0) {
            setSecondLevelCode(0);
        } else {
            setSecondLevelCode(account.code.toString().padStart(2, "0"));
        }


        let level3 = Accounts.filter(
            (item) => item.iLevel == 3 && item.Account_Code.startsWith(account.code) && item.Account_Code.length == 9
        );




        if (level3.length == 0) {
            setThirdLevelCode(account.code + "1001");
            setAccountCode(account.code + "1001");
        }
        else {
            let lastItemOfLevel3 = level3[level3.length - 1];
            let third_code = parseInt(lastItemOfLevel3.Account_Code) + parseInt(1);
            setAccountCode(third_code);
            setThirdLevelCode(third_code);
        }


        let level3Accounts = Accounts.filter(
            (item) => item.iLevel == 3 && item.Account_Code.startsWith(account.code)
        );

        const level3Select = level3Accounts.map((item) => ({
            value: item.ID,
            label: item.Account_Description,
            code: item.Account_Code
        }));


        setThirLevelAccounts(level3Select);

    }




    const handleSubmit = () => {


        if(AccountName == "")
        {
            message.error("Enter account name");
            return;
        }

        setLoadingSaveAccount(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            CompanyID: CompanyID,
            Account_Code: AccountCode,
            Account_Description: AccountName,
            Remarks: AccountRemarks,
            FYear: FYear,
        };

        // console.log(data);

        var api_config = {
            method: "post",
            url: Config.base_url + "Accounts/AddNewAccount",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setLoadingSaveAccount(false);
                    form.resetFields();
                    message.success(response.data.status_message);
                    //navigate("/Accounts/ManageAccounts");
                } else {
                    message.error(response.data.status_message);
                }

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);

            });


    };


    return (
        <>
            <div className="sub-menu-wrap">
                <h5>Accounts</h5>
                <AccountsMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">
                            <NavLink to="/accounts/manage"><ArrowLeftIcon /></NavLink>
                            Add Account
                        </h3>

                    </div>
                    <Form
                        layout="horizontal"
                        className="form-compact"
                        onFinish={handleSubmit}
                        scrollToFirstError={true}
                        form={form}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    >
                        <div className="form-section">
                            <div className="form-header">
                                <Row>
                                    <Col md={10} xs={24}>
                                        <div className="left-form-tittle">
                                            <span className='count'>1</span>
                                            <h2>Level 1</h2>
                                            <p>Basic access with limited permissions for essential functionalities.</p>
                                        </div>
                                    </Col>
                                    <Col md={14} xs={24}>
                                        <div style={{ maxWidth: "500px" }}>
                                            <Form.Item label="Select First Level">
                                                <Select
                                                    placeholder="Select First Level"
                                                    allowClear={true}
                                                    loading={loading}
                                                    options={FirstLevelAccounts}
                                                    onChange={handleFirstChange}
                                                    filterOption={(input, option) =>
                                                        option.label.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item label="Account Code">
                                                {FirstLevelCode}
                                            </Form.Item>


                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-header">
                                <Row>
                                    <Col md={10} xs={24}>
                                        <div className="left-form-tittle">
                                            <span className='count'>2</span>
                                            <h2>Level 2</h2>
                                            <p>Intermediate access with expanded permissions for moderate functionalities.</p>
                                        </div>
                                    </Col>
                                    <Col md={14} xs={24}>
                                        <div style={{ maxWidth: "500px" }}>
                                            <Form.Item label="Select Second Level">
                                                <Select
                                                    disabled={AccountLevel != 2}
                                                    options={SecondLevelAccounts}
                                                    onChange={handleSecondChange}
                                                    filterOption={(input, option) =>
                                                        option.label.toLowerCase().includes(input.toLowerCase())
                                                    }

                                                />
                                            </Form.Item>
                                            <Form.Item label="Account Name">
                                                <Input disabled={AccountLevel != 2} onChange={(e) => setAccountName(e.target.value)} />
                                            </Form.Item>
                                            <Form.Item label="Remarks">
                                                <Input disabled={AccountLevel != 2} onChange={(e) => setAccountRemarks(e.target.value)} />
                                            </Form.Item>

                                            {
                                                SecondLevelCode > 0 &&
                                                <Form.Item label="Account Code">
                                                    {SecondLevelCode}
                                                </Form.Item>
                                            }


                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-header">
                                <Row>
                                    <Col md={10} xs={24}>
                                        <div className="left-form-tittle">
                                            <span className='count'>3</span>
                                            <h2>Level 3</h2>
                                            <p>Advanced access with full permissions for comprehensive functionalities.</p>
                                        </div>
                                    </Col>
                                    <Col md={14} xs={24}>
                                        <div style={{ maxWidth: "500px" }}>
                                            <Form.Item label="Account Name">
                                                <Input disabled={AccountLevel != 3} onChange={(e) => setAccountName(e.target.value)} />
                                            </Form.Item>
                                            <Form.Item label="Remarks">
                                                <Input disabled={AccountLevel != 3} onChange={(e) => setAccountRemarks(e.target.value)} />
                                            </Form.Item>
                                            {
                                                ThirdLevelCode > 0 &&
                                                <Form.Item label="Account Code">
                                                    {ThirdLevelCode}
                                                </Form.Item>
                                            }


                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="form-footer">
                            <Button onClick={handleSubmit} type="primary" size="large" loading={loadingSaveAccount}>
                                Save
                            </Button>
                        </div>
                    </Form>


                </div>
            </div>

        </>
    );
}

export default AddAccount;