import { DeleteOutlined, DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Col, Form, Input, Row, Select, message, Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Config from '../../Config'
import axios from 'axios'
import dayjs from 'dayjs'
import BanksMenu from './BanksMenu'

const AddBankPayments = () => {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");

    const navigator = useNavigate();
    const [form] = Form.useForm();
    const [formMain] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(null);
    const [AccountCode, setAccountCode] = useState("");
    const [AccountDesc, setAccountDesc] = useState("");
    const [AccountsDropdown, setAccountsDropdown] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [Level, setLevel] = useState("0");
    const [OrderBy, setOrderBy] = useState("");
    const [AccountID, setAccountID] = useState("");
    const [BankPaymentList, setBankPaymentList] = useState([]);


    useEffect(() => {
        document.title = 'Bank Payments';
    }, []);


    const onFinish = (value) => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            FYear: FYear,
            CompanyID: CompanyID,
            AccountCode: '',
            ...value,
        }
        let url;
        // if (props.CustomerID === undefined) {
        //     url = 'Customer/AddCustomer'
        // }
        // else {
        //     url = 'Customer/UpdateCustomer';
        //     data.ID = props.CustomerID;
        // }

        // console.log(data);

        let api_config = {
            method: "post",

            url: Config.base_url + url,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    message.success(response.data.status_message);
                    setLoading(false);
                    formMain.resetFields();
                } else {
                    message.error(response.data.status_message);
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    };


    const handleAccountChange = (value, account) => {
        setAccountCode(account.code);
        setAccountDesc(account.desc);

        form.setFieldValue('Description', account.desc);
        form.setFieldValue('Code', account.code);
    }

    const handleAddItem = (formData) => {

        formData['Date'] = dayjs(formData['Date']).format("YYYY-MM-DD");

        const newItem = {
            Seq: BankPaymentList.length,
            ...formData
        };

        setBankPaymentList([...BankPaymentList, newItem]);
        form.resetFields();
    }
 
    const handleDeleteBankPayment = (index) => {
        const updatedFormData = [...BankPaymentList];

        // index, number of items to be removed, new elements to be added
        updatedFormData.splice(index, 1);
        setBankPaymentList(updatedFormData);
    };

    const handleFilters = (formData) => {
        if (formData['Level'] != undefined) {
            setLevel(formData['Level']);
        }
        else if (formData['AccountID'] != undefined) {
            setAccountID(formData['AccountID']);
        }
        else if (formData['OrderBy'] != undefined) {
            setOrderBy(formData['OrderBy']);
        }
        else if (formData['AccountCode'] != undefined) {
            setAccountCode(formData['AccountCode']);
        }
        else {

        }
    }

    const onReset = () => {
        form.resetFields();
        setLevel('0');
        setAccountCode('');
        setAccountID('');
    };

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    const handleDateChange = (date, dateString) => {
        setDate(dateString); 
        console.log(dateString) 
    }


    return (
        <>
            <div className="sub-menu-wrap">
                <h5>Bank</h5>
                <BanksMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">Add Bank Payments</h3>
                    </div>
                    <div className="filters-wrap">
                        <Form
                            onFinish={handleFilters}
                            form={form}
                        >

                            <Form.Item name="Bank" label='Bank'>
                                <Select
                                    showSearch
                                    placeholder="Select Bank"
                                    optionFilterProp="children"
                                    style={{ width: "500px" }}
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'tom',
                                            label: 'Tom',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <Row>
                        <Col xs={24} md={24}>
                            <Form layout="horizontal" onFinish={handleAddItem} form={form}>
                                <div className="ant-table-custom table-compact">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Ref. No.</th>
                                                <th scope="col">Mode</th>
                                                <th scope="col">Nominal Account</th>
                                                <th scope="col">Detail</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {BankPaymentList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.Date}</td>
                                                    <td>{item.Ref_No}</td>
                                                    <td>{item.Mode}</td>
                                                    <td>{item.Nominal_Account}</td>
                                                    <td>{item.Detail}</td>
                                                    <td>{item.Amount}</td>
                                                    <td>
                                                        <ul className="inline-action">
                                                            <li>
                                                                <Link to={`#/`} onClick={() => handleDeleteBankPayment(index)} className="red">
                                                                    <DeleteOutlined />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}

                                            <tr className="table-form">
                                                <td>
                                                    <Form.Item name="Date" rules={[{ required: false, },]}>
                                                        <DatePicker
                                                           // defaultValue={Date == null ? dayjs() : dayjs(Date)}
                                                            allowEmpty={false}
                                                           // onChange={handleDateChange}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Ref_No">
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            placeholder="Ref. No."
                                                            variant="borderless"
                                                            style={{
                                                                width: 250,
                                                            }}
                                                            onChange={handleAccountChange}
                                                            loading={loadingAccounts}
                                                            options={AccountsDropdown}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Mode">
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            placeholder="Mode"
                                                            variant="borderless"
                                                            style={{
                                                                width: 250,
                                                            }}
                                                            onChange={handleAccountChange}
                                                            loading={loadingAccounts}
                                                            options={AccountsDropdown}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Nominal_Account">
                                                        <Input placeholder="Nominal Account" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Detail">
                                                        <Input placeholder="Detail" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name="Amount">
                                                        <Input placeholder="Amount" variant="borderless" />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Button icon={<PlusCircleIcon />} htmlType="submit"></Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default AddBankPayments